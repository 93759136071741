import React, { memo, useMemo, useState } from 'react';
import { Divider, Skeleton } from 'antd';

import { useTranslation } from 'next-i18next';
import style from './index.module.less';
import { ArrowRightOutlined } from '@ant-design/icons';
import ImprovePbContent from '@/components/Common/ImprovePbContent';
import { useRouter } from 'next/router';
import { PlanbowlArticleListModel } from '@/types/home';
import { useUserProvider } from '@/context/UserProvider';
import { ReportType } from '@/components/Common/CreateReportAlertContent';
import { imgUrl } from '@/shared/utils';
import Cookies from 'js-cookie';

const SingleContent = ({ dataList }: { dataList?: PlanbowlArticleListModel[] }) => {
  const {
    isLogin,
    onCurrentReportType,
    onOpenCreateReportAlert,
    onCurrentReport,
    onGetStartedClick,
    onOpenLoginModal,
    // sceneListData,
  } = useUserProvider();
  const router = useRouter();
  const { t } = useTranslation('common');
  const [modelOpen, setModelOpen] = useState(false);

  const sceneList = useMemo(() => {
    return dataList?.map((item) => (
      <div
        key={item.name}
        style={{ backgroundImage: `url(${imgUrl(item.image, 'mid')})` }}
        className={style['single__List--item']}
      >
        <div className={style.title}>{item.name.replace(',', '')}</div>
        <div className={style.model}>
          <span
            onClick={() =>
              router.push(`/sample-report/${item.identification}/${item.name.replace(',', '')}`)
            }
          >
            {t('View Sample')}
          </span>
          <Divider className={style.divider} type="vertical" />
          <span onClick={() => onDashboardClick(item)}>{t('Build Guide')}</span>
        </div>
      </div>
    ));
  }, [dataList]);

  const onDashboardClick = (report?: PlanbowlArticleListModel) => {
    const token = Cookies.get('userToken');
    if (token) {
      onCurrentReportType(ReportType.SINGLE_SCENE);
      onOpenCreateReportAlert(true);
      onCurrentReport(report || null);
    } else {
      onGetStartedClick('none');
      onOpenLoginModal(true);
    }
  };

  return (
    <div className={style.single}>
      <h3 className={style.single__title}>{t('Single-Threat Readiness')}</h3>
      <p className={style.single__desc}>{t('SingleDesc')}</p>
      <div className={style.single__List}>
        {sceneList?.length ? sceneList : null}
        {!sceneList?.length ? <SingleLoading /> : null}
        <div className={style['single__List--item']} onClick={() => setModelOpen(true)}>
          <ArrowRightOutlined className={style.icon} />
          <div className={style.desc}>
            <div className={style.descTitle}>{t('Can’t find what you need?')}</div>
            <div className={style.descText}>{t('Share your feedback here')}</div>
          </div>
        </div>

        <ImprovePbContent open={modelOpen} setOpen={setModelOpen} />
      </div>
    </div>
  );
};

const SingleLoading = () => (
  <>
    <Skeleton.Image
      style={{ width: '100%', padding: '0' }}
      className={style['single__List--item']}
    />
    <Skeleton.Image
      style={{ width: '100%', padding: '0' }}
      className={style['single__List--item']}
    />
    <Skeleton.Image
      style={{ width: '100%', padding: '0' }}
      className={style['single__List--item']}
    />
    <Skeleton.Image
      style={{ width: '100%', padding: '0' }}
      className={style['single__List--item']}
    />
    <Skeleton.Image
      style={{ width: '100%', padding: '0' }}
      className={style['single__List--item']}
    />
    <Skeleton.Image
      style={{ width: '100%', padding: '0' }}
      className={style['single__List--item']}
    />
    <Skeleton.Image
      style={{ width: '100%', padding: '0' }}
      className={style['single__List--item']}
    />
  </>
);

export default memo(SingleContent);
