import { IxParams } from '@/shared/typings';
import {
  SearchSuggestions,
  SuggestionsCategories,
  SuggestionsData,
} from '@/types/marketplace.interface';

import 'dayjs/locale/ja';
import 'dayjs/locale/de';
import 'dayjs/locale/pt';
import 'dayjs/locale/zh-tw';
import dayjs from 'dayjs';

export const isS3Image = (url: string) => {
  return url?.includes('pumpsoul.com') ?? false; //) || (url?.includes('res-back-test.pumpsoul.com') ?? false);
  // https://pumpsoul-test.s3.amazonaws.com/aigc_image/ed08a1d895954d0e8411dceba452f8a4.jpg__op__resize,m_mfit,w_500,h_500,q_80__op__format,f_webp
};

export function imgUrl(v: string, type?: 'small' | 'mid' | 'big') {
  const imgType = type ?? 'mid';
  switch (imgType) {
    case 'small':
      return imgix(v, { w: 200, h: 200 });
    case 'big':
      return imgix(v, { w: 1000, h: 1000 });
    default:
      return imgix(v, { w: 500, h: 500 });
  }
}

export function imgix(v: string, params?: IxParams) {
  if (!v || !isS3Image(v)) return v || '';

  const w = params?.w ?? 100;
  const h = params?.h ?? 100;
  const q = params?.q ?? 80;
  return v + `__op__resize,m_mfit,w_${w},h_${h}__op__format,f_webp`;

  // return v + `__op__resize,m_mfit,w_${w},h_${h},q_${q}__op__format,f_webp`

  // const url = new URL(v);
  // url.host = 'pietra.imgix.net';
  // if (!params?.auto) {
  //   url.searchParams.append('auto', 'compress,format');
  // }
  // if (params) {
  //   for (const [k, v] of Object.entries(params)) {
  //     url.searchParams.append(k, v);
  //   }
  // }
  // return url.toString();
}

export const isMobileByUserAgent = (userAgent: string) => {
  return Boolean(
    userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i),
  );
};
export const capitalizeString = (text: string) => {
  return (text || '')
    .toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');
};

type transformType =
  | 'pico'
  | 'icon'
  | 'thumb'
  | 'small'
  | 'compact'
  | 'medium'
  | 'large'
  | 'grande'
  | '1024'
  | '2048';

/**
 * @link https://www.evernote.com/l/AT59JlZLi4xFW72l5E6dvv2Jtt8eHEOPbrY
 * @param url
 * @param size
 */
export function imageTransform(url: string, size: transformType | string) {
  if (typeof url !== 'string') return url;

  const pietraS3SizeKeys = ['compact', 'large', 'grande', '1024'];
  const shopifySizeKeys = [
    'pico',
    'icon',
    'thumb',
    'small',
    'compact',
    'medium',
    'large',
    'grande',
    '1024',
    '2048',
  ];
  if (isS3Image(url)) {
    if (!pietraS3SizeKeys.includes(size)) return url;
    const regex = /(\/public.*?\/)/;
    return url.replace(regex, `/public_${size}/`);
  } else if (typeof size === 'string') {
    size = size.toLowerCase();
    if (!shopifySizeKeys.includes(size)) return url;
    if (['1024', '2048'].includes(size)) {
      size = `${size}x${size}`;
    }
  } else if (typeof size === 'number') {
    if (!(size > 0)) return url;
  } else {
    return url;
  }

  const pathArray = url.split('/');
  if (!/shopify\.com/.test(pathArray[2])) return url;

  const lastIndex = pathArray.length - 1;
  const tmpArray = pathArray[lastIndex].split('.');

  if (!/^(jpg|png|jpeg|webp)/i.test(tmpArray[1])) return url;

  const basename = tmpArray[0].replace(
    RegExp(`_(${shopifySizeKeys.join('|')}|\\d+x\\d+)$`, 'ig'),
    '',
  );
  tmpArray[0] = basename + `_${size}`;
  pathArray[lastIndex] = tmpArray.join('.');
  return pathArray.join('/');
}

export const convertSuggestions = (
  data: SuggestionsData,
  subcategoryValueMap: any,
): SearchSuggestions => {
  const arr: SearchSuggestions = {
    stores: [],
    items: [],
    categories: [],
  };
  if (data.storeList) {
    arr.stores = data.storeList;
  }
  // TODO <editor-fold desc="Suspicious unused code">
  const allCategories: SuggestionsCategories[] = [];
  if (data.categories) {
    data.categories.forEach(function (item) {
      allCategories.push({
        text: subcategoryValueMap[item] || item,
        value: item,
        param: 'category',
      });
    });
  }
  if (data.materials) {
    data.materials.forEach(function (item) {
      allCategories.push({
        text: capitalizeString(item),
        value: item,
        param: 'material',
      });
    });
  }
  if (data.gemstones) {
    data.gemstones.forEach(function (item) {
      allCategories.push({
        text: capitalizeString(item),
        value: item,
        param: 'gemstone',
      });
    });
  }
  arr.categories = allCategories.length > 4 ? allCategories.slice(0, 4) : allCategories;
  // </editor-fold>
  if (data.list) {
    arr.items = data.list.map((text) => ({ text }));
  }
  return arr;
};

export const shopAllLink = (title: string, params: any) => {
  if (params.exactRoute) {
    return params.exactRoute;
  }
  const urlPath = title ? new URLSearchParams({ ...params, title }) : '';
  return `/shop/all?${urlPath}`;
};

export const zeroPaddedNumber = (num: number): string => {
  return `${num}`.padStart(2, '0');
};

export const formatPrice = (price: string): string => {
  if (!price) return '';

  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
    parseFloat(price),
  );
};

export const capitalizeFirstLetter = (str: string): string => {
  if (str.length == 0 || str == null || str == undefined) {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export function areEqualIgnoreCase(str1: string, str2: string): boolean {
  return str1.toLowerCase() === str2.toLowerCase();
}

export const getPathTitle = (str: string): string => {
  if (str?.length == 0 || str == null || str == undefined) {
    return '-';
  }
  let resStr = str
    .replace(/[^\w\s\-]+/g, '')
    .replaceAll(' ', '-')
    .toLowerCase();
  return resStr.length === 0 ? str : resStr;
};

export const objToQueryString = (obj: { [key: string]: any }): string => {
  if (obj === undefined || obj === null) {
    return '';
  }
  const filteredObj: { [key: string]: any } = {};
  for (const key in obj) {
    if (obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
      filteredObj[key] = obj[key];
    }
  }
  return new URLSearchParams(filteredObj).toString();

  // const queryString = Object.entries(obj)
  //   .map(([key, value]) => {
  //     // if (value === undefined || value === null) {
  //     //   return ''
  //     // }
  //     const tempVal = `${value}`;
  //     return `${encodeURIComponent(key)}=${encodeURIComponent(tempVal)}`;
  //   })
  //   .join('&');
  // return queryString;
};

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export function isEmailValid(email?: string): boolean {
  if (email == null || email == undefined || email.length == 0) {
    return false;
  }
  return emailRegex.test(email);
}

export function getRandomInt(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min)) + min;
}

export function getRandomColor(): string {
  const randomPart = () => Math.floor(Math.random() * 256).toString(16);
  const padZero = (color: string) => (color.length === 1 ? `0${color}` : color);

  const red = padZero(randomPart());
  const green = padZero(randomPart());
  const blue = padZero(randomPart());

  return `#${red}${green}${blue}`;
}

export const getColorFromString = (str: string): string => {
  if (!str) return '#000000'; // 返回默认值或处理空字符串情况

  // 定义颜色映射类型
  const tempColorMap: Record<string, string> = {
    A: '#87C6EA',
    B: '#679DEE',
    C: '#735A4F',
    D: '#783E25',
    E: '#A0B648',
    F: '#FFA07A',
    G: '#63BD63',
    H: '#C44E46',
    J: '#51C199',
    K: '#B68663',
    L: '#D2C656',
    M: '#E45792',
    N: '#4E4CD4',
    O: '#6A6733',
    P: '#FFB6C1',
    Q: '#BBB5B8',
    R: '#FD6565',
    S: '#FDA565',
    T: '#FF7F38',
    U: '#3768C8',
    V: '#8B57DF',
    W: '#D83757',
    X: '#DDB25F',
    Y: '#FFC93F',
    Z: '#BDB76B',
  };
  const firstChar = str[0].toUpperCase();
  return tempColorMap[firstChar] || '#999'; // 默认返回黑色
};

export const getTypeColor = (type: string) => {
  switch (type?.toLocaleLowerCase()) {
    case 'Cold Wave'?.toLocaleLowerCase():
    case 'Flash Flood'?.toLocaleLowerCase():
    case 'Flood'?.toLocaleLowerCase():
    case 'Snow Avalanche'.toLocaleLowerCase():
    case 'Tsunami'.toLocaleLowerCase():
      return {
        color: '#3892E5',
        backgroundColor: '#3892E51A',
      };
    case 'Complex Emergency'?.toLocaleLowerCase():
    case 'Technological Disaster'.toLocaleLowerCase():
      return {
        color: '#0C0C0C',
        backgroundColor: '#0C0C0C1A',
      };
    case 'Earthquake'?.toLocaleLowerCase():
    case 'Land Slide'.toLocaleLowerCase():
    case 'Mud Slide'.toLocaleLowerCase():
      return {
        color: '#C9654F',
        backgroundColor: '#C9654F1A',
      };
    case 'Epidemic'?.toLocaleLowerCase():
    case 'Insect Infestation'?.toLocaleLowerCase():
      return {
        color: '#0D6E4B',
        backgroundColor: '#0D6E4B1A',
      };
    case 'Extratropical Cyclone'?.toLocaleLowerCase():
    case 'Severe Local Storm'.toLocaleLowerCase():
    case 'Storm Surge'.toLocaleLowerCase():
    case 'Tropical Cyclone'.toLocaleLowerCase():
      return {
        color: '#725CFF',
        backgroundColor: '#725CFF1A',
      };
    case 'Fire'?.toLocaleLowerCase():
    case 'Heat Wave'.toLocaleLowerCase():
    case 'Heat Wave'.toLocaleLowerCase():
    case 'Volcano'.toLocaleLowerCase():
    case 'Wild Fire'.toLocaleLowerCase():
      return {
        color: '#ED3333',
        backgroundColor: '#ED33331A',
      };
    default:
      return {
        color: '#0C0C0C',
        backgroundColor: '#0C0C0C1A',
      };
  }
};

// 生成一个随机id
export function randomId(length: number) {
  return Array.from(crypto.getRandomValues(new Uint8Array(length)), (byte) =>
    ('0' + (byte & 0xff).toString(36)).slice(-1),
  ).join('');
}

// 解决报告页返回变量冲突
export function randomVar(str: string) {
  return str
    .replaceAll('protocol', `protocol${randomId(6)}`)
    .replaceAll('hostname', `hostname${randomId(6)}`)
    .replaceAll('port', `port${randomId(6)}`)
    .replaceAll('apiBaseUrl', `apiBaseUrl${randomId(6)}`);
}

// 时间格式化
export function formatDate(date: string, locale: string) {
  return dayjs(date)
    .locale(locale === 'zh_TW' ? 'zh-tw' : locale)
    .format('MMMM D,YYYY');
}
