import { Modal, Input, Button, message } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'next-i18next';

import style from './index.module.less';
import { feedbackScene } from '@/lib/service';

const { TextArea } = Input;

const ImprovePbContent = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  const { t } = useTranslation('common');
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    if (!input) return;
    // todo
    setLoading(() => true);
    feedbackScene(input)
      .then(() => {
        message.success(t('The feedback was successful, thank you for your feedback'));
        setInput('');
        setOpen(false);
      })
      .catch((e) => {
        message.error(e.message);
      })
      .finally(() => {
        setLoading(() => false);
      });
  };

  return (
    <Modal
      onCancel={() => setOpen(false)}
      open={open}
      footer={null}
      width={421}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <div className={style.model}>
        <h3>{t('Help Us Improve Planbowl!')}</h3>
        <p className={style.desc}>
          {t(
            'We want to meet your needs. If you didn’t find the disaster or emergency scenario you were looking for, please let us know!',
          )}
        </p>
        <TextArea
          value={input}
          className={style.textarea}
          onChange={(e) => setInput(e.target.value)}
          placeholder={t('What specific disaster or emergency scenario would you like us to add?')}
        />
        <div className={style.btnContent}>
          <Button
            loading={loading}
            style={{ padding: '0 24px', height: '38px', backgroundColor: '#0D6E4B' }}
            type="primary"
            onClick={handleSubmit}
          >
            {t('Sumbit')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ImprovePbContent;
